import { getPageInfo, PageInfoType, PageCurrentType, PageResponseType, PageModelType } from '@/services/site';
import React, { useCallback, useState } from "react";

// 页面提示
export default function usePageModel(): PageModelType {
    const [list, setList] = useState<Record<string, PageInfoType>>({});
    const [tips, setTips] = useState<PageCurrentType>({
        name: '',
        status: 0,
    });

    const loadPages: PageModelType['loadPages'] = useCallback(async (key, login = true) => {
        if (list[key]) {
            return {
                success: true,
                msg: '从本地加载信息',
            }
        }
        const info: PageResponseType = await getPageInfo({ key, login, replace: true });
        if (info.ret === 0) {
            setList({
                ...list,
                [key]: info.data,
            });
            return {
                success: true,
                msg: info.message,
            }
        }
        return {
            success: false,
            msg: info.message,
        };
    }, [list, getPageInfo]);

    const { name } = tips;
    const visibleTips = useCallback(async (key: String = '') => {
        if (key === name) return;
        if (key === '') {
            setTips({
                name: '',
                status: 0,
            });
            return {
                success: true,
                msg: '结束当前提示',
            };
        }
        setTips({
            name: key,
            status: 0,
        });
        const info = await loadPages(key);
        setTips({
            name: key,
            status: info.success ? 1 : -1,
        });
        
        return info;
    }, [list, name, loadPages]);

    return {
        current: tips.name === '' ? null : (list[tips.name]||null),
        tips,
        loadPages,
        visibleTips,
    }
};