// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app_frontend/migdat_web/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__page' */'@/layouts/page'), loading: LoadingComponent}),
    "routes": [
      {
        "exact": true,
        "path": "/link",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LinkerPage' */'@/pages/LinkerPage'), loading: LoadingComponent}),
        "title": "页面跳转至第三方网页"
      },
      {
        "exact": true,
        "path": "/nopower",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__nopower__index' */'@/pages/user/nopower/index'), loading: LoadingComponent}),
        "title": "没有权限"
      },
      {
        "path": "/auth",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__auth__index' */'@/layouts/auth/index'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/nologin'), loading: LoadingComponent})],
        "routes": [
          {
            "exact": true,
            "path": "/auth",
            "p404": true
          },
          {
            "exact": true,
            "path": "/auth/forget",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Forget' */'@/pages/user/Forget'), loading: LoadingComponent}),
            "title": "忘记密码"
          },
          {
            "exact": true,
            "path": "/auth/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'@/pages/user/Login'), loading: LoadingComponent}),
            "title": "登录"
          },
          {
            "exact": true,
            "path": "/auth/register",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Register' */'@/pages/user/Register'), loading: LoadingComponent}),
            "title": "注册"
          },
          {
            "exact": true,
            "path": "/auth/share/:uid",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Share' */'@/pages/user/Share'), loading: LoadingComponent}),
            "title": "邀请好友"
          },
          {
            "export": true,
            "path": "/auth/help/:path",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Help' */'@/pages/user/Help'), loading: LoadingComponent}),
            "title": "帮助中心",
            "exact": true
          },
          {
            "path": "/auth/bind",
            "title": "绑定店铺",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Bind' */'@/pages/user/Bind'), loading: LoadingComponent}),
            "exact": true,
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__site__p404__index' */'@/pages/site/p404/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/payit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__auth__index' */'@/layouts/auth/index'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/shop'), loading: LoadingComponent})],
        "routes": [
          {
            "exact": true,
            "path": "/payit",
            "p404": true
          },
          {
            "exact": true,
            "path": "/payit/detial/:mark_sell/track/:tid",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__gold__Payit__index' */'@/pages/gold/Payit/index'), loading: LoadingComponent}),
            "title": "在线充值"
          },
          {
            "exact": true,
            "path": "/payit/sync",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__gold__Payit__GoldSync' */'@/pages/gold/Payit/GoldSync'), loading: LoadingComponent}),
            "title": "同步账号金币数"
          }
        ]
      },
      {
        "path": "/portal",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/shop'), loading: LoadingComponent})],
        "routes": [
          {
            "exact": true,
            "path": "/portal",
            "p404": true
          },
          {
            "exact": true,
            "path": "/portal/gold",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__gold__index' */'@/pages/gold/index'), loading: LoadingComponent}),
            "title": "购买金币"
          },
          {
            "path": "/portal/center",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__center___layout' */'@/pages/center/_layout'), loading: LoadingComponent}),
            "routes": [
              {
                "exact": true,
                "path": "/portal/center",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__center__index' */'@/pages/center/index'), loading: LoadingComponent}),
                "title": "商家中心"
              },
              {
                "exact": true,
                "path": "/portal/center/shop",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__center__shop__index' */'@/pages/center/shop/index'), loading: LoadingComponent}),
                "title": "绑定店铺"
              },
              {
                "exact": true,
                "path": "/portal/center/shop/add",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__center__shop__Create' */'@/pages/center/shop/Create'), loading: LoadingComponent}),
                "title": "新增店铺"
              },
              {
                "exact": true,
                "path": "/portal/center/shop/info/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__center__shop__id' */'@/pages/center/shop/[id]'), loading: LoadingComponent}),
                "title": "绑定店铺详情"
              },
              {
                "exact": true,
                "path": "/portal/center/share",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__center__share__index' */'@/pages/center/share/index'), loading: LoadingComponent}),
                "title": "邀请返利"
              },
              {
                "exact": true,
                "path": "/portal/center/gold",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__center__gold__index' */'@/pages/center/gold/index'), loading: LoadingComponent}),
                "title": "金币记录"
              },
              {
                "exact": true,
                "path": "/portal/center/info",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__center__user__index' */'@/pages/center/user/index'), loading: LoadingComponent}),
                "title": "基本信息"
              },
              {
                "exact": true,
                "path": "/portal/center/info/uppwd",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__center__InfoUpPwd' */'@/pages/center/InfoUpPwd'), loading: LoadingComponent}),
                "title": "修改密码"
              },
              {
                "exact": true,
                "path": "/portal/center/task",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__center__task__index' */'@/pages/center/task/index'), loading: LoadingComponent}),
                "title": "任务记录"
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__site__p404__index' */'@/pages/site/p404/index'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/portal/task",
            "routes": [
              {
                "exact": true,
                "path": "/portal/task",
                "p404": true
              },
              {
                "exact": true,
                "path": "/portal/task/copy/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__task__store__edit' */'@/pages/task/store/[edit]'), loading: LoadingComponent}),
                "title": "一键重发"
              },
              {
                "exact": true,
                "path": "/portal/task/edit/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__task__store__edit' */'@/pages/task/store/[edit]'), loading: LoadingComponent}),
                "title": "修改任务"
              },
              {
                "exact": true,
                "path": "/portal/task/info/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__task__info__id' */'@/pages/task/info/[id]'), loading: LoadingComponent}),
                "title": "任务详情"
              },
              {
                "exact": true,
                "path": "/portal/task/pay/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__task__pay__id' */'@/pages/task/pay/[id]'), loading: LoadingComponent}),
                "title": "发布任务，购买金币"
              },
              {
                "exact": true,
                "path": "/portal/task/payfor/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__task__payfor__id' */'@/pages/task/payfor/[id]'), loading: LoadingComponent}),
                "title": "提交并支付任务"
              },
              {
                "exact": true,
                "path": "/portal/task/send/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__task__send__id' */'@/pages/task/send/[id]'), loading: LoadingComponent}),
                "title": "提交任务"
              },
              {
                "exact": true,
                "path": "/portal/task/store/:store",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__task__store__store' */'@/pages/task/store/[store]'), loading: LoadingComponent}),
                "title": "创建任务"
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__site__p404__index' */'@/pages/site/p404/index'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__site__p404__index' */'@/pages/site/p404/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__open__index' */'@/layouts/open/index'), loading: LoadingComponent}),
        "routes": [
          {
            "exact": true,
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__site__index' */'@/pages/site/index'), loading: LoadingComponent}),
            "title": "首页"
          },
          {
            "path": "/help",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__site__Help___layout' */'@/pages/site/Help/_layout'), loading: LoadingComponent}),
            "routes": [
              {
                "exact": true,
                "path": "/help",
                "p404": true
              },
              {
                "exact": true,
                "path": "/help/:type/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__site__Help__id' */'@/pages/site/Help/[id]'), loading: LoadingComponent}),
                "title": "帮助详情"
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__site__p404__index' */'@/pages/site/p404/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
