// @ts-nocheck
import dayjs from '/app_frontend/migdat_web/node_modules/dayjs';
import antdPlugin from 'antd-dayjs-webpack-plugin/src/antd-plugin';

import isSameOrBefore from '/app_frontend/migdat_web/node_modules/dayjs/plugin/isSameOrBefore';
import isSameOrAfter from '/app_frontend/migdat_web/node_modules/dayjs/plugin/isSameOrAfter';
import advancedFormat from '/app_frontend/migdat_web/node_modules/dayjs/plugin/advancedFormat';
import customParseFormat from '/app_frontend/migdat_web/node_modules/dayjs/plugin/customParseFormat';
import weekday from '/app_frontend/migdat_web/node_modules/dayjs/plugin/weekday';
import weekYear from '/app_frontend/migdat_web/node_modules/dayjs/plugin/weekYear';
import weekOfYear from '/app_frontend/migdat_web/node_modules/dayjs/plugin/weekOfYear';
import isMoment from '/app_frontend/migdat_web/node_modules/dayjs/plugin/isMoment';
import localeData from '/app_frontend/migdat_web/node_modules/dayjs/plugin/localeData';
import localizedFormat from '/app_frontend/migdat_web/node_modules/dayjs/plugin/localizedFormat';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(weekYear);
dayjs.extend(weekOfYear);
dayjs.extend(isMoment);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);

dayjs.extend(antdPlugin);
