import { createTask, getTaskInfoService, getTaskPrice, TaskFreeDefault, TaskInfoDefault, TaskInfoType, TaskModelType, taskTypeIndex, updateTask } from '@/services/task';
import { ApiResponseType } from '@/utils/api';
import Storage from '@/utils/storage/deviceStorage';
import { useCallback, useEffect, useState } from "react";
import { useModel } from 'umi';

export default function useTaskModel<T extends TaskInfoType>(): TaskModelType<T> {
    const { initialState = {} } = useModel('@@initialState');
    const { uid = 0 } = initialState;

    const [taskDemand, setTaskDemand] = useState<TaskModelType<T>['taskDemandType']>({} as TaskModelType<T>['taskDemandType']);
    const [taskInfo, setTaskInfo] = useState<T>({} as T);
    const [taskType, setTaskType] = useState<TaskModelType<T>['taskType']>([]);

    const getPointName = useCallback((data: Pick<TaskInfoType, 'id'|'free'|'type'> & { name?: string; }): string => {
        const { id = 0, free = 0, name = '', type = 1 } = data;
        const key = name ? `task-${name}` : (id > 0 ? 'taskMode' : 'taskType');

        return id > 0 ? `${key}.${id}` : `${key}.${free === 1 ? 0 : type}`;
    }, []);

    // 创建taskInfo
    const createTaskInfo: TaskModelType<T>['createTaskInfo'] = useCallback(async (data = {}) => {
        try {
            const point = getPointName(data);
            const info: TaskInfoType = await Storage.local({ point });

            setTaskInfo(info);
            return info;
        } catch(e) {
            const { type = -1 } = data;
            const task = {
                ...TaskInfoDefault,
                ...(type === 0 ? TaskFreeDefault : {}),
                ...data,
            };

            setTaskInfo(task);
            return task;
        }
    }, [getPointName, setTaskInfo, Storage]);

    const getDemand: TaskModelType<T>['getDemand'] = useCallback(type => !taskDemand[type] && getTaskPrice(type).then(info => setTaskDemand(item => ({
        ...item,
        [type]: Object.keys(info).length ? info : null,
    }))), [taskDemand, getTaskPrice, setTaskDemand]);

    // 获取任务类型
    const getTaskType: TaskModelType<T>['getTaskType'] = useCallback(async () => {
        const type = await taskTypeIndex();
        setTaskType(type);

        return type.length||Promise.reject('获取任务类型失败');
    }, [setTaskType]);

    // 更新taskInfo
    const upTaskInfo: (data: Partial<TaskInfoType>) => void = useCallback(data => setTaskInfo(task => {
        const fields = Object.keys(data).filter(key => task[key] !== data[key]);
        return fields.length === 0 ? task : {
            ...task,
            ...data,
        };
    }), [setTaskInfo]);

    // 获取taskInfo
    const getTaskInfo: TaskModelType<T>['getTaskInfo'] = useCallback(async (id, name) => {
        try {
            const point = getPointName({ id, name });
            const info: TaskInfoType = await Storage.local({ point });

            setTaskInfo(info);
            return {
                v: '1.0',
                ret: 0,
                data: info,
                message: '从本地缓存获取信息',
            };
        } catch {
            const info = await getTaskInfoService(id, name);
            if (info.ret === 0) {
                const data = {
                    ...info.data,
                    status: 0,
                    name,
                };

                info.data = data;
                setTaskInfo(info.data);
            }
            return info;
        }
    }, [getPointName, setTaskInfo, Storage]);

    // 提交任务
    const sendTaskInfo: TaskModelType<T>['sendTaskInfo'] = useCallback(async ({ name, ...info }) => {
        const [success, msg, data] = await (name === 'edit' ? updateTask(info) : createTask(info));
        success && Storage.remove({
            point: getPointName(info),
        });
        
        return { success, msg, data };
    }, [createTask, createTaskInfo, getPointName, Storage]);

    useEffect(() => {
        const { id = -1 } = taskInfo;
        -1 !== id && Storage.save({
            point: getPointName(taskInfo),
            data: id > 0 ? taskInfo : {
                ...taskInfo,
                status: 0,    // 发布任务时，永远只保存状态0，以便提交失败等情况回到页面继续提交
            },
        });
    }, [taskInfo, getPointName, Storage]);

    const { type = -1 } = taskInfo;
    useEffect(() => {
        type >= 0 && uid > 0 && getDemand(type);
    }, [type, uid, getDemand]);
    
    return {
        taskDemand,
        taskInfo,
        taskType,
        createTaskInfo,
        getDemand,
        getTaskInfo,
        getTaskType,
        sendTaskInfo,
        upTaskInfo,
    } as count;
}