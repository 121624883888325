
import Api from '@/utils/api';
import type { ApiResponseType } from '@/utils/api';
import { EmptyObject } from '@/utils/storage/deviceStorage';

export type ShopItemType = {
    id: number;
    pingtai: string;
    shop_name: string;
    parent_type: string;
    sub_type: string;
    shop_url: string;
    shopid: string;
    sellerid: string;
    shop_icon: string;
    status: number;
};

export type ShopBindItemType = {
    code: string;
    created_at: number;
    delay: number;
    goods_url: string;
    id: string;
    log: string;
    read_at: number;
    shop_id: number;
    status: 0|1|2;
    uid: number;
    updated_at: number;
};

export type ShopKeyType = 'alibaba'|'fliggy'|'jd'|'pdd'|'taobao'|'tmall';
export type ShopGroupType = {
    [key in ShopKeyType]?: ShopItemType[];
};

export enum shopStatus {
    unpass, repay, repass, pass, lock
};

export const shopStatusKey = {
    unpass: '审核不通过',
    repay: '待支付',
    repass: '待审核',
    pass: '已绑定',
    lock: '冻结中',
};

export type ShopModelType = {
    syncShopList: () => Promise<ShopItemType[]>;
};

export type ShopCodeType = {
    code: string;
    time: number;
};

export async function bindShop(data: Pick<ShopBindItemType, 'code'|'goods_url'>): Promise<void> {
    const info: ApiResponseType = await Api.Post(`shop`, { 
        signature: 'site.shop.store',
        data 
    });
    return info.ret === 0 ? Promise.resolve() : Promise.reject(info.message);
}

export async function bindPass(data: { action: 0|1, id: string }): Promise<ShopBindItemType> {
    const info: ApiResponseType = await Api.Post('notifications', { 
        signature: 'shop.notifications.store',
        data,
    });
    if (info.ret !== 0) return Promise.reject(info);

    const { action } = data;
    const { data: item }: { data: ShopBindItemType } = info;

    const status = [2, 1];
    const success = status[data.action] === item.status && (action === 0 ? item.read_at > 0 : true);

    return success ? Promise.resolve(item) : Promise.reject(info);
}

export async function findBind(code: string): Promise<ShopBindItemType> {
    const info: ApiResponseType = await Api.Get(`shop/code/info/${code}`);
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info.message);
}

export async function getBindInfo({ id }: { id: string }): Promise<ShopBindItemType> {
    const info: ApiResponseType = await Api.Get(`notifications/${id}`);
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info.message);
}

export async function getBindList(): Promise<ShopBindItemType[]> {
    const info: ApiResponseType = await Api.Get('shop/bind/list');
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info);
}

export async function getShopCode(): Promise<ShopCodeType> {
    const info: ApiResponseType = await Api.Get('shop/code/info');
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info.message);
}

export async function getShopInfo(shop_id: number): Promise<ShopItemType> {
    const info: ApiResponseType = await Api.Get(`shop/${shop_id}`);
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info.message);
}

export async function getShopIndex(): Promise<ShopItemType[]> {
    const info: ApiResponseType<ShopItemType[]> = await Api.Get('shop');
    return info.ret === 0 ? info.data : [];
}