import { getShopIndex, ShopItemType, ShopModelType } from '@/services/shop';
import { useCallback, useState } from "react";
import { useModel } from 'umi';

/**
 * @returns 待全部重新优化，流程变了，默认app启动时候就获取店铺信息
 */
export default function useShopModel(): ShopModelType {
    const { setInitialState } = useModel('@@initialState');

    // 以上接口全废弃，因为店铺需要实时获取状态
    const syncShopList: ShopModelType['syncShopList'] = useCallback(async () => {
        const shop = await getShopIndex();
        setInitialState(data => ({
            ...data,
            shop,
        }));
        return shop;
    }, [getShopIndex, setInitialState]);
    
    return {
        syncShopList,
    };
}