import Storage from '@/utils/storage/deviceStorage';
import { userInfo, UserInfoType } from '@/services/user';
import { getShopIndex } from './services/shop';

/**
 * 正常流程是app-auth-wrap-layout-comment
 *  - 如果没有登录，不会请求`userInfo`，将在`auth.tsx`分流
 *  - 如果伪造一个`token`，请求`userInfo`失败得不到结果，将在`auth.tsx`分流
 * ---
 * 然而`useModel`是个例外，他不会经过`auth.tsx`分流
 *  - 所以要通过`useModel`发起需要认证的请求前需要确认是有有登录
 */
export async function getInitialState(): Promise<Partial<UserInfoType>> {
    const base = { uid: 0, shop: [] };

    Storage.flush();
    if (Storage.userToken() === null) {
        return base;
    }

    const [data, shop] = await Promise.all([userInfo(base), getShopIndex()]);
    data.shop = shop;

    return data;
};

export function onRouteChange({ location, matchedRoutes }) {
    const num = matchedRoutes.length - 1;

    // 判断404
    location.p404 = matchedRoutes[num].route.p404 === true || location.pathname !== matchedRoutes[num].match.url;

    // 基于location一个path
    location.path = matchedRoutes[num].route.path;
    location.title = matchedRoutes[num].route.title||'';

    // 修改网页标题
    document.title = [matchedRoutes[num].route.title||'', '米果数据'].filter(i => i).join(' - ')
}