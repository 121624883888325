import { getServicePrice, SerivcePriceType, ServiceCostType, serviceDefault, ServiceItemType, ServiceModelType, ServiceType, taskService } from "@/services/service";
import { TaskRangeType } from "@/services/task";
import { UserInfoType } from "@/services/user";
import Storage, { EmptyObject } from "@/utils/storage/deviceStorage";
import { useCallback, useEffect, useState } from "react";
import { useModel } from "umi";

export default function useServiceModel(): ServiceModelType<ServiceType> {
    const { initialState } = useModel('@@initialState');
    const { uid = 0 } = initialState||{};

    const [priceIndex, setPriceIndex] = useState<SerivcePriceType>({} as SerivcePriceType);
    const [service, setService] = useState<ServiceType>({} as ServiceType);
    const [status, setStatus] = useState<number>(0);

    const getPointName = useCallback((data: TaskRangeType): string => {
        const { id = 0, free = 0, type = 1 } = data;
        return id > 0 ? `serviceMode.${id}` : `serviceType.${free === 1 ? 0 : type}`;
    }, []);

    const saveService: (task: TaskRangeType, data: ServiceType) => ServiceType = useCallback((task, data) => {
        const point = getPointName(task);
        Object.keys(data).length > 0 ? Storage.save({ point, data }) : Storage.remove({ point });

        setService(data);
        return data;
    }, [getPointName, setService, Storage]);

    const createService: ServiceModelType<ServiceType>['createService'] = useCallback(async (task, data = {}) => {
        try {
            const point = getPointName(task);
            const service: ServiceType = await Storage.local({ point });

            setService(service);
            return service;
        } catch(e) {
            const info = {
                ...serviceDefault,
                ...data,
            };
            return saveService(task, info);
        }
    }, [serviceDefault, getPointName, setService, Storage]);

    const getService: (task: TaskRangeType) => Promise<EmptyObject|ServiceType> = useCallback(async (task) => {
        const { id } = task;
        if (id <= 0) return {};
        try {
            const point = getPointName(task);
            const service: ServiceType = await Storage.local({ point });

            saveService(service);
            return service;
        } catch(e) {
            const index: ServiceItemType[] = await taskService(id);
            const service = index.reduce((data: Partial<ServiceType>, item) => {
                const { key, value } = item;
                const info = {};
                switch (key) {
                    case 'gold_add':
                        if (value !== 0) {
                            info.gold_add = 1;
                            info.gold_add_price = value;
                        }
                        break;
                    case 'dotime_num':
                        info[key] = value.map(i => Math.min(21, i));
                        break;
                    default:
                        info[key] = key === 'dotime' ? [value] : value;
                }
                return {
                    ...data,
                    ...info,
                }
            }, {
                ...serviceDefault,
                dotime: [],
            });

            saveService(task, service);
            return service;
        }
    }, [serviceDefault, getPointName, saveService, Storage, taskService]);

    const getPrice = useCallback(async (): Promise<boolean> => {
        if (Object.keys(priceIndex).length > 0) return true;
        setStatus(status => status === 0 ? status : 0);

        const info = await getServicePrice();
        const success = Object.keys(info).length > 0;

        success && setPriceIndex(info);
        setStatus(success ? 1 : -1);

        return success;
    }, [priceIndex, getServicePrice, setPriceIndex, setStatus]);

    const { task_press = 0 } = service;
    useEffect(() => {
        uid > 0 && status === 0 && task_press === 1 && getPrice();
    }, [status, task_press, uid, getPrice]);

    return {
        priceIndex,
        service,
        status,
        createService,
        getPrice,
        getService,
        saveService,
    } as const;
}