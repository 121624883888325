import { getLogs, getTaskInfo, sendAction } from "@/services/task";
import { LoopsModelType, NotifyItemType } from "@/services/user";
import { useMap } from "ahooks";
import find from "lodash/find";
import { useCallback, useEffect, useState } from "react";
import { useModel } from "umi";

type cancelLoopMethod = (name: string, data: NotifyItemType, expire: number) => void;

export default function useLoopsModel(): LoopsModelType {
    const [map, { get, set, setAll }] = useMap<string, NotifyItemType>([]);
    const [uptime, setUptime] = useState<number>(0);
    const [limit, setLimit] = useState<number>(500);

    const { initialState, setInitialState } = useModel('@@initialState');
    const { getUserInfo } = useModel('useUserModel', model => ({
        getUserInfo: model.getUserInfo,
    }));

    const cancelLoopItem: cancelLoopMethod = useCallback((name, data, expire) => {
        const { id, time } = data;
        set(name, data);
        getLogs(id).then(index => {
            const item = find(index, ['type', 'cancel']);
            if (item === undefined) {
                if (time + expire < Date.now()) {
                    set(name, {
                        ...data,
                        error: new Error('取消任务超时，建议尝试刷新当前页面'),
                        loading: false,
                        time: Date.now(),
                    });
                } else {
                    setTimeout(() => cancelLoopItem(name, data, time), limit);
                }
            } else {
                getUserInfo(initialState, false).then(success => {
                    const info = success ? { data: item } : { 
                        error: new Error('已取消任务，同步金币失败，建议尝试刷新当前页面')
                    };
                    set(name, {
                        ...data,
                        ...info,
                        loading: false,
                        time: Date.now(),
                    });
                });
            }
            return index;
        });
    }, [initialState, limit, find, getLogs, getUserInfo, set]);

    const cancelSync: LoopsModelType['cancelSync'] = useCallback((id, expire = 30000) => {
        const name = `cancel-${id}`;
        get(name) === undefined && cancelLoopItem(name, {
            loading: true, 
            time: Date.now(),
            type: 'cancel',
            id,
        });
    }, [cancelLoopItem, get]);

    const clearAll = useCallback(() => setAll([]), [setAll]);

    const payTaskSync: LoopsModelType['payTaskSync'] = useCallback(id => {
        const name = `pay-${id}`;
        if (get(name) !== undefined) return;

        const data = {
            loading: true, 
            time: Date.now(),
            type: 'pay',
            id,
        };
        set(name, data);
        getTaskInfo(id).then(info => {
            set(name, {
                ...data,
                data: info,
                loading: info.status === 1,
                time: Date.now(),
            });
            info.status === 1 && sendAction({
                data: { key: 'payit' },
                id,
            }).then(({ data: { user, ...task } }) => {
                setInitialState(userInfo => ({
                    ...userInfo,
                    ...user,
                }));
                set(name, {
                    ...data,
                    data: task,
                    loading: false,
                    time: Date.now(),
                });
            }).catch(error => {
                getUserInfo(initialState, false);
                set(name, {
                    ...data,
                    loading: false,
                    time: Date.now(),
                    error,
                });
            });
        }).catch(
            error => set(name, {
                ...data,
                loading: false,
                time: Date.now(),
                error,
            })
        );
    }, [initialState, get, getTaskInfo, getUserInfo, sendAction, set, setInitialState]);

    const taskStoreSync: LoopsModelType['taskStoreSync'] = useCallback((id, loading = true) => {
        const data = {
            time: Date.now(),
            type: 'task',
            id,
            loading, 
        };

        if (!loading) data.data = true;
        set('task', data);

        loading && getUserInfo(initialState, false).then(success => {
            const info = success ? { data: true } : {
                error: new Error('同步账号金币失败'),
            };
            set('task', {
                ...data,
                ...info,
                loading: false,
                time: Date.now(),
            });
        });
    }, [initialState, get, getUserInfo]);

    // 必须通过这里才能刷新组件
    useEffect(() => {
        setUptime(Date.now());
    }, [map, setUptime]);

    return {
        map,
        uptime,
        cancelSync,
        clearAll,
        get,
        payTaskSync,
        setLimit,
        taskStoreSync,
    };
};