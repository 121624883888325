import { getShopIndex } from '@/services/shop';
import { userInfo, UserModelType } from '@/services/user';
import { useCallback } from 'react';
import { useModel } from 'umi';

export default function useUserModel(): UserModelType {
    const { setInitialState } = useModel('@@initialState');

    const getUserInfo: UserModelType['getUserInfo'] = useCallback(async (data = {}, syncShop = true) => {
        const [info, shop] = await Promise.all([
            userInfo(data), syncShop ? getShopIndex() : []
        ]);

        if (syncShop) {
            info.shop = shop;
        }
        
        setInitialState(init => ({
            ...init,
            ...info,
        }));
        return info.uid > 0;
    }, [setInitialState]);

    return {
        getUserInfo,
    };
};