import Api, { ApiResponseType } from '@/utils/api';
import { UserTokenType } from '@/utils/storage/deviceStorage';
import { ShopItemType } from './shop';

export type AuthModelType = {
    forget: (data: ForgetFormType) => Promise<Omit<RegisterType, 'token'>>;
    register: (data: RegisterProps) => Promise<Omit<RegisterType, 'token'>>;
    signin: (user_login: string, user_pass: string) => Promise<LogoutInfoType>;
    signout: () => Promise<LogoutInfoType>;
};

export type CallbackItemType = {
    appid: `${number}`;
    randstr: string;
    ret: number;
    ticket: string;
    bizState?: Record<string, string|int>;    // 自定义透传参数
};

export type ForgetFormType = {
    code: number;
    sid: number;
    user_login: number;
    user_pass: string;
};

export type LoginInfoType = {
    msg: string;
    success: boolean;
    token: UserTokenType;
};

type LoginType = {
    user_login: string;
    user_pass: string;
};

export type LogoutInfoType = {
    msg: string;
    success: boolean;
};

export interface LoopsModelType {
    map: Map<string, NotifyItemType>;
    uptime: number;
    cancelSync: (id: number, expire?: number) => void;
    clearAll: () => void;
    get: (key: string) => NotifyItemType|undefined;
    payTaskSync: (id: number) => void;
    setLimit: (time: number) => void;
    taskStoreSync: (type: number, loading?: boolean) => void;
}

type NotificationKey = 'promotions'|'shop'|'task';

export type NotifyItemType = {
    id: number;
    loading: boolean;
    time: number;
    type: 'cancel'|'pay'|'task';
    data?: UserInfoType|SendActionResType;
    error?: Error;
};

type RegisterType = {
    msg: string;
    ret: number;
    token: UserTokenType;
}

export type RegisterProps = {
    code: `${number}`;
    share: number;
    sid: number;
    user_login: number;
    user_pass: string;
};

export type ShareCostType = Record<'exchange'|'order'|'shop'|'total', number>;

export type ShareItemType = Pick<UserInfoType, 'qq'|'wechat'> & {
    give: number;
    phone: string;
    shop: boolean;
    time: number;
    uid: number;
};

export type ShareProps = {
    current: number;
    pageSize: 10|20|50|100;
};

export interface TableDataInterface<T extends []> {
    current: number;
    data: T;
    pageSize: number;
    total: number;
}

export type UpUserType = UserInfoType|Partial<UserMetaType>;

export type UserFormType = Partial<UserMetaType> & {
    code?: `${number}`;
    qq?: `${number}`;
    sid?: number;
    time: [number, number];
    user_login?: `${number}`;
    user_pass?: string;
    wechat?: string;
};

export type UserInfoType = {
    gold: number;
    isSeller: boolean;
    phone: `${number}`;
    qq: `${number}`;
    safe: boolean;
    shop: ShopItemType[];
    status: number;
    task_free_num: number;
    task_free_used: number;
    type: number;
    uid: number;
    wechat: string;
    user_version: number;
};

// 后续还有再继续加
type UserMetaKey = keyof UserMetaType;

export type UserMetaType = {
    notification: Record<NotificationKey, 0|1>;
    notify_time: [number, number];
};

export interface UserModelType {
    getUserInfo: (info?: Partial<UserInfoType>, syncShop?: boolean) => Promise<boolean>;
};

export type VerifyData = {
    token: string;
    sid: `${number}`,
};

type VerifyResType = [
    string, 
    {
        err_msg: string;
        evil_level: `${number}`,
        response: `${number}`,
        sid: `${number}`,
    },
];

export async function captchVerify(data: CallbackItemType): Promise<VerifyResType> {
    const info: ApiResponseType<VerifyResType> = await Api.post('../captcha/verify', { data });
    return info.ret === 0 ? Promise.resolve(info.data): Promise.reject(info);
}

export async function sendCode(data: VerifyData & { phone: `${number}`; }, reset?: boolean = false): Promise<void> {
    const info: ApiResponseType<VerifyResType> = await Api[reset ? 'Post' : 'post']('../captcha/code', { data });
    return info.ret === 0 ? Promise.resolve() : Promise.reject(info);
}

export async function shareCost(): Promise<ShareCostType> {
    const info: ApiResponseType<ShareCostType> = await Api.Get('user/share/cost');
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info);
}

export async function upUserInfo(data: UserFormType): Promise<UpUserType> {
    const info: ApiResponseType<UpUserType> = await Api.Post('user', {
        signature: 'seller.user.store',
        data,
    });
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info);
};

export async function userForget(data: ForgetFormType): Promise<RegisterType> {
    const info: ApiResponseType<UserTokenType> = await Api.Post('forget', { data });
    const { ret, message: msg, data: resData = {} } = info;
    
    return {
        msg,
        ret,
        token: ret === 0 ? resData : {},
    }
}

export async function userInfo<T extends UserInfoType>(data?: Partial<T> = {}): T {
    const info: ApiResponseType<T> = await Api.Get('user');
    const user = info.ret === 0 ? info.data : data;

    return user as T;
}

export async function userLogin(data: LoginType): Promise<LoginInfoType> {
    const info: ApiResponseType<UserTokenType> = await Api.Post('login', { data });
    const { ret, message, data: resData = {} } = info;
    
    const success = info.ret === 0;
    return {
        success,
        msg: ret === 101 ? '账号或密码不正确' : message,
        token: success ? resData : {},
    }
};

export async function userLogout(): LogoutInfoType {
    const info: ApiResponseType = await Api.Get('../logout');
    return {
        success: info.ret === 0,
        msg: info.message,
    };
};

export async function userMeta(keys: UserMetaKey[]): Promise<Partial<UserMetaType>> {
    const info: ApiResponseType<Partial<UserMetaType>> = await Api.Get(`user/meta/${keys.join()}`);
    return info.ret === 0 ? info.data : {};
}

export async function userShare(data: ShareProps): Promise<TableDataInterface<ShareItemType[]>> {
    const info: ApiResponseType<TableDataInterface<ShareItemType[]>> = await Api.Post(
        'user/share/index', { data }
    );
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info);
}

export async function userRegister(data: RegisterProps): Promise<RegisterType> {
    const info: ApiResponseType<UserTokenType> = await Api.Post('register', { data });
    const { ret, message: msg, data: resData = {} } = info;
    
    return {
        msg,
        ret,
        token: ret === 0 ? resData : {},
    }
}

/*export async function testReq(data) {
    const info = await Api.post('https://local.migdat.com/task/sendit', { data });
    return Promise.resolve(info);
}*/