import { getGoodsInfoService, goodsInfo, GoodsInfoType, GoodsLinkType, GoodsMapItemType, GoodsModelType, GoodsType } from '@/services/goods';
import { ApiResponseType } from '@/utils/api';
import Storage, { EmptyObject } from '@/utils/storage/deviceStorage';
import { useMap, useRequest } from 'ahooks';
import React, { useCallback, useEffect, useState } from 'react';

export default function useGoodsModel(): GoodsModelType {
    const [map, { set, get }] = useMap<`${number}`, GoodsMapItemType>([]);
    const { runAsync } = useRequest(goodsInfo, {
        manual: true,
    });

    const getGoodsInfo = useCallback(async (link: GoodsLinkType): Promise<GoodsMapItemType> => {
        const { id, pingtai, url } = link;
        const point = `goodsInfo.${id}.pt.${pingtai}`;

        try {
            const info: GoodsInfoType = await Storage.local({ point });
            const goods = {
                type: {
                    status: 0,
                    tips: '从本地缓存获取商品信息',
                },
                info,
            };

            map.set(url, goods);
            return goods;
        } catch(e) {
            try {
                const info = await runAsync(url);
                Storage.save({ data: info, point });

                const goods = {
                    type: {
                        status: 0,
                        tips: '从线上获取商品缓存信息',
                    },
                    info,
                };

                map.set(url, goods);
                return goods;
            } catch(type) {
                const goods = { type };

                map.set(url, goods);
                return goods;
            }
        }
    }, [map, Storage]);
    
    return {
        map,
        get,
        getGoodsInfo,
    } as const ;
}