import Api, { ApiResponseType } from '@/utils/api';
import { EmptyObject } from '@/utils/storage/deviceStorage';
import { Dispatch, SetStateAction } from 'react';
import { useMap } from "ahooks";

export type GoodsLinkType = {
    pingtai: string;
    id: string;
    url: string;
};

export type GoodsInfoType = {
    goods_id: string;
    goods_imgs: string[];
    goods_name: string;
    goods_url: string;
    pingtai: string;
    shop_name: string;
    shop_url: string;
    shopid: string;
    time: number;
};

// -3.正在输入、-2.获取中、-1.等待获取、0.获取成功、101.获取失败、102.获取的不是自己的链接、1.异常提醒（及其他数字，包括：获取的不是淘宝商品链接）
export type GoodsType = {
    status: number;
    tips: string;
};

export type GoodsMapItemType = {
    type: GoodsType;
    info?: GoodsInfoType;
};

export type GoodsInfoModelType = GoodsMapItemType & {
    loading: boolean;
    map: Map<`${number}`, GoodsMapItemType>;
    clear: (type: GoodsType) => void;
    filterGoodsLink: (url: string) => GoodsLinkType;
    run: (goods: string) => Promise<void>;
};

export type GoodsModelType = {
    map: Map<`${number}`, GoodsMapItemType>;
    get: (key: `${number}`) => GoodsMapItemType|undefined;
    // goodsInfo: EmptyObject|GoodsInfoType;
    // goodsType: GoodsType;
    // filterGoodsLink: (url: string) => GoodsLinkType;
    // getGoodsInfo: (goods: GoodsLinkType, refresh: boolean) => Promise<boolean>;
    // setGoodsType: Dispatch<SetStateAction<GoodsType>>;
    getGoodsInfo: (goods: GoodsLinkType) => Promise<GoodsMapItemType>;
};

export async function getGoodsInfoService(goods_url: string, refresh: number = 0): ApiResponseType {
    const info: ApiResponseType = await Api.Post('goods', {
        data: { goods_url, refresh }
    });
    return info;
};

export async function goodsInfo(goods_url: string): Promise<GoodsInfoType> {
    const info: ApiResponseType = await Api.Post('goods', {
        data: { goods_url }
    });

    const { data, message: tips, ret: status } = info;
    return status === 0 ? Promise.resolve(data) : Promise.reject({ status, tips });
}