import { FC } from 'react';
import styles from './loading.less';

const Loading: FC<{tips?: string;}> = ({ tips = '加载中...' }) => [
    <div 
        key="tips"
        className={styles.spinTips}
    >
        {tips}
    </div>,
    <div 
        key="loading"
        className={styles.spinner}
    >
        <div className={styles.rect1}></div>
        <div className={styles.rect2}></div>
        <div className={styles.rect3}></div>
        <div className={styles.rect4}></div>
        <div className={styles.rect5}></div>
    </div>
];

export default Loading;