import Api, { ApiResponseType } from '@/utils/api';
import { StorageDataType, EmptyObject } from '@/utils/storage/deviceStorage';
import { Dayjs } from 'dayjs';
import { SerivcePriceType } from './service';
import { UserInfoType } from './user';

export type GameRangeType = 'add_cart'|'sc_goods'|'sc_shop'|'take_ticket'|'view_pj';
export type GameViewType = 'view_goods';
export type GameSearchType = 'search_key';

export type DailyCostItemType = {
    date: string;
    day: number;
} & {
    [key in (GameRangeType | GameViewType)]: [number, number];
};

export type GameItemType = Record<(GameRangeType | GameViewType | GameSearchType), string>;
export type GameCancelItemType = {
    buyer: string;
    cancel_time: number;
    key: string;
    log: string;
    tag: string;
    take_time: number;
    user_time: string;
};

export type GoldIndexProps = {
    id: number;
    meta_type: string;
};

type LocalUpverifyProps = {
    fkey: string;
    point: string;
    token: string;
};

export type PageType<T> = {
    current: number;
    pageSize: number;
    total: number;
    data: T[];
};

export type PageProps = {
    current?: number;
    pageSize?: number;
};

export type SendActionResType = {
    data: TaskInfoType;
    msg: string;
};

export type SendActionProps = {
    key: string;
    offtime?: Dayjs;
    remark?: string;
    first_date?: Dayjs;
    first_time?: [Dayjs, Dayjs];
    next_date?: Dayjs;
    next_time?: [Dayjs, Dayjs];
};

export type SendTaskInfoType = {
    success: boolean;
    msg: string;
    data: TaskInfoType;
};

export type TakeItemType = {
    buyer: string;
    id: number;
    send_time: number;
    screenshot: GameItemType;
    tag: string;
    task: string;
    type: number;
    timeout: boolean;
    use_time: string;
    log: string;
};

export type TakeListType = {
    current: number;
    pageSize: number;
    total: number;
    field: GameItemType;
    data: TakeItemType[];
};

export type TakeListProps = {
    id: number;
    cancel?: boolean;
    change?: boolean;
    current?: number;
    pageSize?: number;
    unpass?: boolean;
};

export type TaskType = {
    type: number;
    title: string;
    tips: string;
    desc: string;
};

type TaskDetailItemType = {
    desc: string;
    name: string;
};

type TaskDetailType = {
    demand: Record<GameRangeType | GameViewType, TaskDetailItemType>;
};

export type TaskGoldType = {
    demand: Record<GameRangeType | GameViewType, number>;
    detail: TaskDetailType;
    service: SerivcePriceType;
};

type TaskDemandItemType = {
    name: string;
    desc: string;
    price: number;
};

export type TaskDemandType = Record<string, TaskDemandItemType>;
export type CouponItemType = {
    code: string;
    title: string;
    num: number;
};

// taskInfo的status除了和线上保持一致以外，还有一个状态-1：填写后进行校验
export type TaskInfoType = {
    id: number;
    author: number;
    pingtai: string;
    type: number;
    shop_id: number;
    shop_name: string;
    goods_id: string;
    goods_name: string;
    goods_url: string;
    goods_img: string[];
    goods_search_price: number;
    post_time: number;
    pay_time: number;
    plan: number;
    ft_view: number;
    task_day: number;
    task_day_add: number;
    task_view_cost: number;
    task_sc_goods: number;
    task_sc_shop: number;
    task_add_cart: number;
    task_take_ticket: number;
    task_view_pj: number;
    task_gold_cost: number;
    service_gold_cost: number;
    gold_cost_fee: number;
    free: number;
    status: number;
    pay_it: number;
    game: Record<number, number>;
    coupon?: string;
    name?: string;
    sync?: boolean;
};

type CreateTaskResType<T> = { 
    task: T; 
    user?: Pick<UserInfoType, 'gold'|'task_free_num'|'task_free_used'>; 
};

type TaskFilterType = {
    shop_id?: number;
    status?: number[];
    type?: number[];
};

export type TaskItemType = Pick<TaskInfoType, 'author'|'free'|'goods_id'|'goods_img'|'goods_name'|'goods_search_price'|'goods_url'|'id'|'pay_it'|'pingtai'|'post_time'|'shop_name'|'status'|'task_add_cart'|'task_day'|'task_sc_goods'|'task_sc_shop'|'task_take_ticket'|'task_view_cost'|'task_view_pj'|'type'> & {
    game: Record<number, number>;
};

export type TaskListType = {
    current: number;
    data: TaskItemType[];
    pageSize: 10|20|30|50|100;
    total: number;
}

type TaskListProps = Partial<Pick<TaskListType, 'current'|'pageSize'>> & {
    post_time?: [Dayjs, Dayjs];
    order?: 'ascend'|'descend';
    order_key?: 'id'|'post_time';
    filters?: TaskFilterType;
};

export interface TaskModelType<T> {
    taskDemand: Record<number, TaskDemandType|null>;
    taskInfo: T;
    taskType: TaskType[];
    createTaskInfo: (data?: Partial<T>) => Promise<T>;
    getDemand: (type: number) => Promise<void>;
    getTaskType: () => Promise<number>;
    getTaskInfo: (id: number, name: string) => Promise<ApiResponseType<T>>;
    sendTaskInfo: <U extends T>(info: U) => Promise<SendTaskInfoType>;
    upTaskInfo: (data: Partial<T>) => void;
};

export type JobType = {
    type: 'create'|'create_error'|'create_success';
    time: number;
    msg?: string;
    status?: number;
};

export type LogItemType = {
    action: string;
    action_time: string;
    num: number;
    remark: string;
    type: string;
    user: string;
};

type MineIndexType = {
    task: number;
    game: number;
};

export type TaskNotifyType = Pick<TaskInfoType, 'ft_view'|'gold_cost_fee'|'goods_id'|'goods_img'|'goods_name'|'goods_url'|'pay_time'|'post_time'|'status'|'task_day'|'task_day_add'|'task_view_cost'|'type'> & {
    created_at: number;
    delay: number;
    id: string;
    level: 0|1;
    log: string;
    pay: 0|1|2;
    pingtai: string;
    read_at: number;
    shop_name: string;
    task_id: number;
    uid: number;
    updated_at: string;
    user: Pick<UserInfoType, 'gold'|'task_free_num'|'task_free_used'>;
};

export type TaskRangeType = Pick<TaskInfoType, 'free'|'id'|'type'>;
export const TaskInfoDefault = {
    id: 0,
    author: 0,
    pingtai: '',
    type: 1,
    shop_id: 0, 
    shop_name: '',
    goods_id: '',
    goods_name: '',
    goods_url: '',
    goods_img: [],
    goods_search_price: 0,
    post_time: 0,
    pay_time: 0,
    plan: '',
    ft_view: 40,
    task_day: 7,
    task_day_add: 10,
    task_view_cost: 490,
    task_sc_goods: 98,
    task_sc_shop: 0,
    task_add_cart: 98,
    task_take_ticket: 0,
    task_view_pj: 98,
    task_gold_cost: 29400,
    service_gold_cost: 1100,
    gold_cost_fee: 30500,
    free: 0,
    status: 0,
    pay_it: 0,
    sync: false,
    coupon: '',
};

export const TaskFreeDefault = {
    ft_view: 10,
    task_day: 4,
    task_day_add: 0,
    task_view_cost: 40,
    task_sc_goods: 4,
    task_add_cart: 0,
    task_view_pj: 0,
    task_gold_cost: 1760,
    service_gold_cost: 500,
    gold_cost_fee: 2260,
    free: 1,
    coupon: 'free',
};

export const FreeCoupon = {
    code: 'free',
    title: '4天体验任务抵扣券',
    num: 2260,
};

export const PageDefault = {
    current: 1, data: [], pageSize: 20, total: 0
};

export async function createCoupon(code: string): Promise<CouponItemType> {
    const info: ApiResponseType<CouponItemType> = await Api.Post(
        'coupon', { data: { code } }
    );
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info);
}

export async function createTask<T extends TaskInfoType>(data: T): Promise<[boolean, string, CreateTaskResType<T>]> {
    const info: ApiResponseType<CreateTaskResType<T>> = await Api.Post('tasks', { 
        signature: 'site.tasks.store',
        data 
    });
    return [info.ret === 0, info.message, info.data as CreateTaskResType<T>];
}

export async function getDailyCost({ id }: { id: number; }): Promise<DailyCostItemType[]> {
    const info: ApiResponseType<DailyCostItemType[]> = await Api.Get(`tasks/${id}/cost/daily`);
    return info.ret === 0 ? info.data : [];
}

export async function getGoldIndex<T>({ id, meta_type }: GoldIndexProps): Promise<T> {
    // const info: ApiResponseType<T> = await Api.Get(`${location.origin}/api/cost/gold/cancel`);
    const info: ApiResponseType<T> = await Api.Get(`tasks/${id}/cost/gold/${meta_type}`);
    return info.ret === 0 ? info.data : (meta_type === 'cost' ? {} : []);
}

export async function getGoldList<T>({ id, meta_type, index }: GoldIndexProps & { index: number[] }): Promise<T[]> {
    // const info: ApiResponseType<T> = await Api.Post(`${location.origin}/api/cost/gold/cancel`, {
    const info: ApiResponseType<T> = await Api.Post(`tasks/${id}/cost/gold/${meta_type}`, {
        data: { index }
    });
    return info.ret === 0 ? info.data : [];
};

export async function getMineIndex(): Promise<MineIndexType> {
    const info: ApiResponseType<MineIndexType> = await Api.Get('tasks/mine/index');
    return info.ret === 0 ? info.data : { task: 0, game: 0 };
}

export async function getLogs(id: number): Promise<LogItemType[]> {
    const info: ApiResponseType<LogItemType[]> = await Api.Get(`tasks/${id}/cost/logs`);
    return info.ret === 0 ? info.data : [];
}

export async function getTakeList<T>({ id, cancel = false, current = 1, pageSize = 30, unpass = false }: TakeListProps): Promise<T> {
    // const info: ApiResponseType<T> = await Api.Post(`${location.origin}/api/cost/take`, {
    const info: ApiResponseType<T> = await Api.Post(`tasks/${id}/cost/take`, {
        data: {
            cancel,
            current, 
            pageSize,
            unpass,
        }
    });
    return info.ret === 0 ? info.data : {
        current,
        pageSize,
        total: 0,
        data: [],
    };
}

export async function getTaskCost(): Promise<Record<number, number>> {
    const info: ApiResponseType<Record<number, number>> = await Api.Get('tasks/cost');
    return info.ret === 0 ? info.data : {};
}

// 仅在`useTaskModel`中使用，无论是一键重发还是修改任务，都需要使用copy，其目的拷贝新的主图以便重新发布任务
export async function getTaskInfoService<T = ApiResponseType<TaskInfoType>>(id: number, name?: string = 'edit'): Promise<T> {
    const info: T = await Api.Post(`tasks/${id}/copy`, { data: { name } });
    return info;
}

// 和`getTaskInfo`一样，区别是支持清空任务进度缓存
export async function getTaskInfoCost(id: number, refresh?: boolean = false): Promise<TaskInfoType> {
    const [info] = await Promise.all<[ApiResponseType<TaskInfoType>, any]>([
        Api.Get(`tasks/${id}`),
        refresh ? Api.Delete(`tasks/${id}/cost/cache`) : true
    ]);
    // const info: ApiResponseType<TaskInfoType> = await Api.Get(`tasks/${id}`);
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info);
}

export async function getTaskInfo(id: number): Promise<TaskInfoType> {
    const info: ApiResponseType<TaskInfoType> = await Api.Get(`tasks/${id}`);
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info);
}

export async function getTaskInfoPrice(id: number): Promise<TaskGoldType> {
    const info: ApiResponseType<TaskGoldType> = await Api.Get(`tasks/${id}/cost/price`);
    return (info.ret === 0 ? info.data : {}) as TaskGoldType;
}

export async function getTaskList({ post_time, ...data }: TaskListProps = {}): Promise<TaskListType> {
    if (post_time !== undefined) {
        data.post_time = post_time.map(item => item.format('YYYY-MM-DD'));
    }

    const info: ApiResponseType<TaskListType> = await Api.Post('tasks/list', { data });
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info);
}

export async function getTaskPrice(type: number): Promise<TaskDemandType> {
    const info: ApiResponseType<TaskDemandType> = await Api.Get(`tasks/info/price/${type}`);
    const data = info.ret === 0 ? info.data : {};

    return data as TaskDemandType;
}

export async function getUpToken() {
    const info:ApiResponseType = await Api.Get(`goods/0/uptoken`);
    return {
        token: 0 !== info.ret ? '' : info.data,
        msg: info.message,
    };
};

export async function goodsInfo(goods_url: string):ApiResponseType {
    const info:ApiResponseType = await Api.Post('goods', {
        data: { goods_url }
    });
    return info;
};

export async function localUpverify(data: LocalUpverifyProps): ApiResponseType {
    return await Api.Post('../local/upverify', { data });
}

export async function sendAction<T = SendActionProps>({ id, data }: { id: number; data: T }): Promise<SendActionResType> {
    const info: ApiResponseType<TaskInfoType> = await Api.Patch(`tasks/info/status/${id}`, { 
        signature: 'site.tasks.status.update',
        data: Object.keys(data).reduce((info, key) => ({
            ...info,
            [key]: data[key].format ? data[key].format('YYYY-MM-DD') : (
                !data[key].map ? data[key] : data[key].map(
                    item => item.hours ? item.hours() : item
                )
            ),
        }), {} as T)
    });
    return info.ret !== 0 ? Promise.reject(info) : Promise.resolve({
        data: info.data,
        msg: info.message,
    });
};

export async function taskJobStatus(id: number): Promise<TaskNotifyType> {
    const info: ApiResponseType<TaskNotifyType> = await Api.Get(`tasks/info/status/${id}`);
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info);
}

export async function taskTypeIndex(): Promise<TaskType[]> {
    const info: ApiResponseType<TaskType[]> = await Api.Get('tasks/info/type');
    return info.ret === 0 ? info.data : [];
}

export async function taskPayInfo(id: number): Promise<TaskNotifyType> {
    const info: ApiResponseType<TaskNotifyType> = await Api.Post(`tasks/info/pay/${id}`, {
        data: { check: true },
    });
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info);
}

export async function updateTask<T extends TaskInfoType>(data: T): Promise<[boolean, string, CreateTaskResType<T>]> {
    const info: ApiResponseType<CreateTaskResType<T>> = await Api.Patch(`tasks/${data.id}`, {
        signature: 'site.tasks.update',
        data,
    });
    return [info.ret === 0, info.message, info.data as CreateTaskResType<T>];
}

export async function upRefreshService(data): string[] {
    const info: ApiResponseType = await Api.Post('../uprefresh/goods_url', { data });
    return 0 === info.ret ? info.data : [];
};