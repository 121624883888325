import { CodeItemDefault, TagItemDefault, TagsModelType, TagsRangeType, TaskCodeType, taskTags, TaskTagType } from '@/services/tags';
import { TaskRangeType } from '@/services/task';
import Storage from '@/utils/storage/deviceStorage';
import React, { useCallback, useState } from 'react';

export default function useTagsModel(): TagsModelType {
    const [tags, setTags] = useState<TagsRangeType[]>([]);
    const getPointName = useCallback((data: TaskRangeType): string => {
        const { id = 0, free = 0, type = 1 } = data;
        return id > 0 ? `tagsMode.${id}` : `tagsType.${free === 1 ? 0 : type}`;
    }, []);

    const saveTags: TagsModelType['saveTags'] = useCallback((task, data) => {
        const point = getPointName(task);
        data.length > 0 ? Storage.save({ point, data }) : Storage.remove({ point });

        setTags(data);
        return data;
    }, [getPointName, Storage]);

    const createTags:(
        task: TaskRangeType, total: number, data: Partial<TagsRangeType>
    ) => Promise<TagsRangeType[]> = useCallback(async (task, total, data = {}) => {
        try {
            const point = getPointName(task);
            const tags: TagsRangeType[] = await Storage.local({ point });

            setTags(tags);
            return tags;
        } catch(e) {
            const { type } = task;
            const tagIndex = Array(total).fill({
                ...([2,4].indexOf(type) < 0 ? TagItemDefault : {
                    ...CodeItemDefault,
                    type: type / 2,
                }),
                ...data,
            });
            return saveTags(task, tagIndex);
        }
    }, [getPointName, saveTags, setTags, Storage]);

    const getTags:(task: TaskRangeType, type: 'copy'|'edit') => Promise<TagsRangeType[]> = useCallback(async (task, type) => {
        const { id } = task;
        if (id <= 0) return [];
        try {
            const point = getPointName(task);
            const tags:TagsRangeType[] = await Storage.local({ point });

            saveTags(task, tags);
            return tags;
        } catch(e) {
            const index:TagsRangeType[] = await taskTags(id, type === 'copy');
            saveTags(task, index);

            return index;
        } 
    }, [getPointName, saveTags, Storage, taskTags]);

    return {
        tags,
        createTags,
        getTags,
        saveTags,
    };
};