import Api, { ApiResponseType } from "@/utils/api";
import { EmptyObject } from '@/utils/storage/deviceStorage';
import { TaskRangeType } from "./task";

export type ServiceType = {
    dotime: [0|1|2|3];
    dotime_num: [number, number];
    task_press: 1;
    task_shenhe: 0|1;
    gold_add: 0|1;
    gold_add_price: number;
    task_speed: 0|1;
};

export type ServiceItemType = {
    id: number;
    key: string;
    value: number|number[];
    price: number;
};

export type SerivcePriceType = {
    dotime: number[];
    dotime_num: number;
    gold_add: number[];
    task_press: number;
    task_shenhe: number;
    task_speed: number;
};

export type ServiceCostType = {
    [key in keyof SerivcePriceType]?: number;
};

export interface ServiceModelType<T> {
    priceIndex: SerivcePriceType;
    service: T;
    status: number;
    createService: (task: TaskRangeType, data: Partial<T>) => T;
    getPrice: () => Promise<boolean>;
    getService: (task: TaskRangeType) => Promise<EmptyObject|T>;
    saveService: (task: TaskRangeType, data: T) => T;
};

export const serviceDefault: ServiceType = {
    dotime: [2],
    dotime_num: [6, 21],
    task_press: 1,
    task_shenhe: 0,
    gold_add: 0,
    gold_add_price: 20,
    task_speed: 0,
};

export const serviceItemName = {
    dotime: '访客入店时间分布优化',
    dotime_num: '访客入店时间范围',
    task_press: '审核寻宝截图',
    task_shenhe: '优先审核',
    gold_add: '加赏寻宝佣金',
    gold_add_price: '每单加赏金额',
    task_speed: '优先分配寻宝',
};

export async function getServicePrice(): Promise<Partial<SerivcePriceType>> {
    const info: ApiResponseType = await Api.Get('services');
    return 0 === info.ret ? info.data : {};
}

export async function taskService(id: number):Promise<ServiceItemType[]> {
    const info: ApiResponseType<ServiceItemType[]> = await Api.Get(`tasks/${id}/service`);
    return info.data;
}