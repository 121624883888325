import Api, { ApiResponseType } from '@/utils/api';
import { EmptyObject, StorageDataType, StorageError } from '@/utils/storage/deviceStorage';
import { Dispatch, SetStateAction } from 'react';
import { IRoute } from 'umi';

export type HelpIndexItem = {
    key: string;
    label: string;
    type: 'api'|'index';
    children?: Omit<HelpIndexItem, 'children'|'type'>[];
    name?: string;
};

type PageStatusType = {
    success: boolean;
    msg: string;
};

export type PageCurrentType = {
    name: String;
    status: Number,    // 0.wait, -1.faile, 1.success
};

export type PageInfoType = {
    id: number;
    role: string;
    category: string;
    name: string;
    uid: number;
    title: string;
    content: string;
    type: string;
    time: number;
    active: number;
    status: number;
};

type PagePropsType = {
    key: string;
    login?: boolean;
    replace?: boolean;
};

export type PageResponseType = StorageError|(StorageDataType & {
    data: PageInfoType
});

export type PageModelType = {
    current: PageInfoType|null;
    tips: PageCurrentType;
    loadPages: (key: string, login: true) => Promise<PageStatusType>;
    visibleTips: (key?: string) => Promise<PageStatusType>;
};

export type BreadMapType = Record<string, string>|EmptyObject;
export type BreadModeType = {
    breadcrumb: BreadMapType;
    breadcrumbHistroy: BreadMapType;
    breadcrumbMap: BreadMapType;
    createBreadcrumb: (route: IRoute) => void;
    setbreadcrumbHistroy: Dispatch<SetStateAction<BreadMapType>>;
    setBreadcrumbMap: Dispatch<SetStateAction<BreadMapType>>;
};

export async function getHelpMenu(login?: boolean = false): Promise<HelpIndexItem[]> {
    const info: ApiResponseType<HelpIndexItem[]> = await Api[login ? 'Get' : 'get'](`../notice/site/index`);
    return info.ret === 0 ? Promise.resolve(info.data) : Promise.reject(info);
}

// 获取内容
export async function getPageInfo(data: PagePropsType): Promise<PageResponseType> {
    const { key, login = true, replace = false } = data;
    const info: PageResponseType = await Api[login ? 'Get' : 'get'](`../notice/site/${replace ? 'replace' : 'info'}/${key}`);
    return info;
};