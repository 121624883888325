import { BreadMapType, BreadModeType } from "@/services/site";
import { useCallback, useState } from "react";

// 面包屑
export default function useBreadcrumb(): BreadModeType {
    const [breadcrumb, setBreadcrumb] = useState<Pick<BreadModeType, 'breadcrumbMap'>>({});
    const [breadcrumbMap, setBreadcrumbMap] = useState<Pick<BreadModeType, 'breadcrumbMap'>>({});

    // 历史记录一个值，方便浏览器后退的时候使用
    const [breadcrumbHistroy, setbreadcrumbHistroy] = useState<Pick<BreadModeType, 'breadcrumbHistroy'>>({});

    const createBreadcrumb = useCallback(routes => {
        const list = {};
        const eachList = data => {
            data.forEach(row => {
                if (row.path && row.title) {
                    list[row.path] = row.title;
                }
                row.routes && eachList(row.routes);
            });
        };

        eachList(routes);
        setBreadcrumb(list);
    }, [setBreadcrumb]);

    // 替换path中的url，比如一个动态的路由{ '/task/:id': '/task/123' }
    /*const pushBreadcrumbMap = useCallback(links => {
        setBreadcrumbMap(links);
    }, []);*/

    return {
        breadcrumb,
        breadcrumbMap,
        breadcrumbHistroy,
        createBreadcrumb,
        setbreadcrumbHistroy,
        setBreadcrumbMap
    };
};