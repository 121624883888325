import { AuthModelType, userForget, userInfo, userLogin, userLogout, userRegister } from '@/services/user';
import Storage from '@/utils/storage/deviceStorage';
import { useState, useCallback } from 'react';
import { useModel } from 'umi';

// 登录登出
export default function useAuthModel(): AuthModelType {
    const { setInitialState } = useModel('@@initialState');

    const forget: AuthModelType['forget'] = useCallback(async data => {
        const { msg, ret, token } = await userForget(data);
        if (ret === 0) {
            await Storage.userCreate(token);
        }
        return { msg, ret };
    }, [Storage, userForget]);

    const register: AuthModelType['register'] = useCallback(async data => {
        const { msg, ret, token } = await userRegister(data);
        if (ret === 0) {
            await Storage.userCreate(token);
        }
        return { msg, ret };
    }, [Storage, userRegister]);

    const signin: AuthModelType['signin'] = useCallback(async (user_login, user_pass) => {
        const { success, msg, token } = await userLogin({user_login, user_pass});
        if (success) {
            await Storage.userCreate(token);
        }
        return {
            success,
            msg,
        };
    }, [Storage, userLogin]);

    const signout = useCallback(async () => {
        const info = await userLogout();
        const data = { uid: 0, shop: [] };

        if (info.success) {
            setInitialState(data);
            Storage.clear();
        }
        return info;
    }, [setInitialState, Storage, userLogout]);

    return {
        forget,
        register,
        signin,
        signout,
    };
};