import Api, { ApiResponseType } from '@/utils/api';
import { TaskRangeType } from './task';

export type TagColumnsItemType = {
    dataIndex: string;
    title: string;
};

export type TagListItemType = {
    id: number;
    tag_name: string;
    code: string;
    [key: string]: [number, number];
};

export type TagsCostType = {
    columns: TagColumnsItemType[];
    list: TagListItemType[];
};

export type TaskTagType = {
    id: number;
    tag_name: string;
    view_user: number;
    sort_type: string;
    send_site: string;
    price_limit: {
        min: number;
        max: number;
    };
    service: string[];
    goods_type: string;
};

export type TaskCodeType = {
    id: number;
    type: number;
    code: string;
    tag: string;
    view_user: number;
};

export type TagsRangeType = TaskCodeType|TaskTagType;
export type TagsModelType = {
    tags: TagsRangeType[];
    createTags: <T extends TagsRangeType>(
        task: TaskRangeType, total: number, data: Partial<T>
    ) => Promise<T[]>;
    getTags: (task: TaskRangeType) => Promise<TagsRangeType[]>;
    saveTags: <T extends TagsRangeType>(task: TaskRangeType, data: T[]) => T[];
};

export const TagItemDefault: TaskTagType = {
    id: 0,
    tag_name: '',
    view_user: 1,
    sort_type: '',
    send_site: '',
    price_limit: {
        min: -1,
        max: -1,
    },
    service: [],
    goods_type: '',
};

export const CodeItemDefault: TaskCodeType = {
    id: 0,
    type: 1,
    code: '',
    tag: '',
    view_user: 1,
};

export async function getQrToken() {
    const info: ApiResponseType = await Api.Get(`qr/0/uptoken`);
    return {
        token: 0 !== info.ret ? '' : info.data,
        msg: info.message,
    };
};

export async function getTagsCost(task_id: number): Promise<TagsCostType> {
    const info: ApiResponseType<TagsCostType> = await Api.Get(`tasks/${task_id}/cost/tag`);
    return info.ret === 0 ? info.data : {
        columns: [],
        list: [],
    };
}

export async function taskTags(task_id: number, copy?: boolean = false): Promise<TagsRangeType[]> {
    // const info: ApiResponseType<T> = await Api.Get(`${location.origin}/api/tasks/tags`);
    const info: ApiResponseType<TagsRangeType[]> = await Api.Get(
        copy ? `tasks/${task_id}/tags/copy` : `tasks/${task_id}/tags`
    );
    return info.ret === 0 ? info.data : [];
}